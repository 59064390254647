.legend-control-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: rgba(240, 240, 240, 0.8);
    border-radius: 3px;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    cursor: default;
}

.legend-control-row {
    margin: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    line-height: 20px;
    min-width: 100px;
}

.legend-control-box {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: #aaa;
    vertical-align: middle;
}

.legend-control-title {
    display: inline-block;
    margin-left: 0px;    
    font-family: sans-serif;
    font-weight: 400;
    font-size: 1.125em;
    text-align: left;
    vertical-align: top;
    padding-bottom: 6px;
    padding-right: 2px;
}

.legend-control-text {
    display: inline-block;
    margin-left: 5px;    
    font-family: sans-serif;
    font-size: 1em;
    vertical-align: middle;
}