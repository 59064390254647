.ol-zoom-in {
    cursor: pointer
}

.ol-zoom-out {
    cursor: pointer
}

.draw-control-container {
    position: fixed;
    top: 74px;
    float: right;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 4px;
}

/*rgba(255, 255, 255, 0.4);*/

.draw-control-btn {
    display: block;
    margin: 2px;
    padding: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    height: calc(1.4 * 1.375em);
    width: calc(1.2 * 1.375em);
    line-height: .4em;
    background-color: rgba(0, 60, 136, 0.5);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.draw-control-icon {
    margin: 2px;
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.9);
}

.draw-control-icon:hover {
    height: 92%;
    width: 92%;
    background-color: rgba(255, 255, 255, 1);
}

.draw-control-hand {
    -webkit-mask: url(icons/hand.svg) no-repeat center;
    mask: url(icons/hand.svg) no-repeat center;
}

.draw-control-polygon {    
    -webkit-mask: url(icons/draw-polygon-solid.svg) no-repeat center;
    mask: url(icons/draw-polygon-solid.svg) no-repeat center;
}

.draw-control-paintbrush {    
    -webkit-mask: url(icons/mouse-pointer.svg) no-repeat center;
    mask: url(icons/mouse-pointer.svg) no-repeat center;
}

.draw-control-eyedropper {
    -webkit-mask: url(icons/query.svg) no-repeat center;
    mask: url(icons/query.svg) no-repeat center;
}


.draw-control-trash {
    -webkit-mask: url(icons/trash-alt-solid.svg) no-repeat center;
    mask: url(icons/trash-alt-solid.svg) no-repeat center;
}

.draw-control-selected {    
    background-color: rgba(0, 60, 136, 0.7);
    border: 1px solid black;
    padding: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.draw-control-disabled {    
    background-color: rgba(120, 120, 120, 0.7);
    border: none;
    cursor: not-allowed;
}

.draw-control-icon-disabled {    
    margin: 2px;
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.9);
}
