div.toc_content>p {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.00938em;
}
div.toc_content>ul>* {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.00938em;
}
div.toc_content>ol>* {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.00938em;
}

div.toc_content>h1 {
    font-size: 2.125rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

div.toc_content>h2 {
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
}

div.toc_content>h3 {
    display: block;
    font-size: 1.17em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}