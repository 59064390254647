table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  border-color: #303030;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
}

thead > tr {
    background-color: #f0f0f0;
}

tbody > tr {
    background-color: white;
    line-height: 2;
  }

tbody > tr > td {
  border: solid 1px rgb(219,219,219);
  padding-left: 8px;
  padding-right: 16px;
}

thead > tr > th {
  padding: 5px;
  margin: 0px;
  text-align: left;
  border-bottom: solid 2px #bfbfbf;
  border-top: solid 1px rgb(219,219,219);
  border-left: solid 1px rgb(219,219,219);
  border-right: solid 1px rgb(219,219,219);
  padding-left: 8px;
  padding-right: 16px;
}